import React, { useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';
// @import images icons
import { themeConfig } from 'themeConfig';
import { app_name } from 'js/constants/endpoints';
import { ReactComponent as Cross } from '../../../images/svg/cross.svg';
import { ReactComponent as QrcodeIcon } from '../../../images/svg/qr-code.svg';

let iframStyles = {
	width: '100%',
	boxSizing: 'border-box',
	height: '400px'
};

const YoutubeVideoPopup = ({ closePopup, ...props }) => {
	let { url, videoTitle = null, lessonTitle = null, image = null } = props;
	const qrRef = useRef(null);
	url = url.replace('http://', 'https://');
	if (!url.includes('https://')) {
		url = `https://${url}`;
	}
	if (url.includes('youtube')) {
		url = `${url}${url.includes('?') ? '&' : '?'}autoplay=1`;
	}
	if (url.includes('canva')) {
		url = url.replace('/view', '/view?embed');
	}
	if (url.includes('vimeo')) {
		let videoId = url.match(/\/\d+/)[0].replace('/', '');
		url = `https://player.vimeo.com/video/${videoId}`;
	}

	useEffect(() => {
		const handleEsc = event => {
			if (event.keyCode === 27) {
				console.log('video popup');
				closePopup();
			}
		};
		window.addEventListener('keydown', handleEsc);

		return () => {
			window.removeEventListener('keydown', handleEsc);
		};
	}, []);
	return (
		<div id="videoPopup" className="popupWrapper videoPopup">
			{lessonTitle && (
				<div ref={qrRef} className="qrPrintWrapper">
					<div className="lessondetails">
						<img
							src={image.replace(/(\.[^.]+)$/, '-150x210$1')}
							srcSet={`${image.replace(/(\.[^.]+)$/, '-300x420$1')} 2x`}
							alt={lessonTitle}
						/>
						<div>
							<h1>{videoTitle}</h1>
							<h2>{lessonTitle}</h2>
						</div>
					</div>
					<QRCode size={400} value={url} />
					<img src={themeConfig[app_name].logo} className="logo" alt={themeConfig[app_name].siteTitle} />
				</div>
			)}

			<div className="popup" style={{ maxWidth: '650px' }}>
				<button
					type="button"
					className="closePopup"
					onClick={() => closePopup()}
				>
					<Cross width={18} />
				</button>

				{lessonTitle && (
					<div className="titleRow">
						<h3>{videoTitle}</h3>
						<ReactToPrint
							trigger={() => (
								<button type="button" className="printButton">
									<QrcodeIcon />
								</button>
							)}
							pageStyle={
								'@media print{@page{size: portrait; margin: 6mm 10mm 9mm 10mm;} body{margin:0;} .pageContainer{background: white !important;} .printButton{display: none;}}'
							}
							content={() => qrRef.current}
						/>
					</div>
				)}

				<div>
					{url && (
						<iframe
							style={iframStyles}
							src={url}
							title="onderbouw online"
							allowFullScreen
							frameBorder="0"
							// allow="autoplay"
						/>
					)}
				</div>
			</div>
			<span className="overlayBackground" />
		</div>
	);
};

export default YoutubeVideoPopup;
